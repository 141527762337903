import React, { useEffect } from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import useStoryblok from "../lib/storyblok";
import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "../components/dynamicComponent";
import { Helmet } from "react-helmet";
import BlogTemplate from "../templates/Blog";
import BlogNext from "../sections/BlogNext";

const DoNotIndex = () => {
  return <Helmet robots={`noindex`} />;
};

function StoryblokEntry({ data, location }) {
  if (
    data.storyblokEntry.full_slug == `footer` ||
    data.storyblokEntry.full_slug == `header`
  ) {
    return DoNotIndex;
  }

  let story = data.storyblokEntry;

  story = useStoryblok(story, location);

  const components = story.content.blocks?.map((blok) => {
    return <DynamicComponent blok={blok} key={blok._uid} />;
  });

  const padBottom = story.content.pad_bottom || false;

  useEffect(() => {
    if (location.hash === '#contact') {
      const contactButton = document.getElementById('contact-trigger');
      if (contactButton) {
        contactButton.click();
      }
    }
  }, [location.hash]);


  // check full_slug to see if it's a project subpage

  const logoAnimation = story.content.logo_animation || false;

  return (
    <Layout
      location={location}
      {...sbEditable(story.content)}
      logoAnimation={logoAnimation}
    >
      <SEO
        keywords={[`Appian Lawyers`]}
        title={story.content.seo_title || ""}
        description={story.content.seo_description || ""}
        socialSharing={story.content.social_sharing?.filename || ""}
      />

      {data.storyblokEntry.field_component == "Blog Post" && (
        <BlogTemplate entry={data.storyblokEntry} />
      )}

      {components}

      {data.storyblokEntry.field_component == "Blog Post" && (
        <BlogNext current={data.storyblokEntry.full_slug} />
      )}

      {padBottom && <div className="h-[65px] lg:h-[144px]"></div>}
    </Layout>
  );
}

export const query = graphql`
  query ($full_slug: String!, $formatString: String = "MMMM Do, YYYY") {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      id
      name
      full_slug
      content
      field_component
      created_at(formatString: $formatString)
    }
  }
`;

export default StoryblokEntry;
