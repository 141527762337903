import React from "react";
import useStoryblok from "../lib/storyblok";
import { graphql, useStaticQuery } from "gatsby";
import FluidImage from "../components/fluidImage";
import Link from "../utils/link";

const BlogSubCard = (props) => {
  return (
    <div
      className={`relative flex flex-col ${
        props.isLarge ? `lg:col-span-3` : `lg:col-span-2`
      }`}
    >
      <Link
        to={props.link}
        className="flex-grow blog-image aspect-[340/600] lg:h-[600px] lg:min-h-[600px] lg:max-h-[600px] lg:aspect-auto"
      >
        <FluidImage image={props.image} />
        <span className="sr-only"> {props.title}</span>
      </Link>
      <div className="flex justify-between lg:items-center pt-7 lg:pt-10 mt-10 border-t border-black flex-col lg:flex-row space-y-7 lg:space-y-0">
        <Link className="h2 max-w-[85%] lg:max-w-xs" to={props.link}>
          {props.title}
        </Link>
        <Link
          className="btn bg-transparent border border-black text-black"
          to={props.link}
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

const BlogNext = (props) => {
  const data = useStaticQuery(graphql`
    query NextQuery($formatString: String = "MMMM Do YYYY") {
      allStoryblokEntry(filter: { field_component: { eq: "Blog Post" } }) {
        nodes {
          group_id
          slug
          path
          parent_id
          full_slug
          field_component
          created_at(formatString: $formatString)
          content
        }
      }
    }
  `);

  const nodes = data.allStoryblokEntry.nodes.map((node) => {
    return useStoryblok(node);
  });

  const current = props.current;

  // locate the next and previous nodes. if the current node is the first or last, then loop around to the other end.

  let next = null;
  let previous = null;

  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].full_slug == current) {
      if (i == 0) {
        next = nodes[nodes.length - 1];
        previous = nodes[i + 1];
      } else if (i == nodes.length - 1) {
        next = nodes[i - 1];
        previous = nodes[0];
      } else {
        next = nodes[i - 1];
        previous = nodes[i + 1];
      }
    }
  }

  if (!next && !previous) {
    return null;
  }

  return (
    <section className="bg-light-grey py-20">
      <div className="container-theme">
        <h2 className="h2 pb-12 lg:pb-16">Next Story</h2>

        <div className="grid lg:grid-cols-5 gap-y-20 lg:gap-10">
          {previous?.content && (
            <BlogSubCard
              isLarge={true}
              image={previous.content.hero_image}
              title={previous.content.title}
              link={next.full_slug}
            />
          )}
          {next?.content && (
            <BlogSubCard
              isLarge={true}
              image={next.content.hero_image}
              title={next.content.title}
              link={next.full_slug}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default BlogNext;
